import React from 'react';
import './Popup.css'; // Import the CSS file for Popup

const Popup = ({ show, onClose, message, title }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="popup-overlay">
      <div className="popup">
        <h2>{title}</h2>
        <p>{message}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Popup;

import React from 'react';
import './Footer.css'; // Import the CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-links">
          <a href="https://tonies.com/en-gb/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          <a href="https://tonies.com/en-gb/terms/" target="_blank" rel="noopener noreferrer">Terms of Service</a>
          <a href="https://tonies.com/en-gb/contact/" target="_blank" rel="noopener noreferrer">Contact Us</a>
        </div>
        <div className="footer-copyright">
          &copy; {new Date().getFullYear()} Tonies. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from 'react';
import './Header.css'; // Import the CSS file

const Header = ({ onLogout, isAuthenticated }) => {
    return (
        <header className="header">
            <div className="header-content">
                <img src="https://images.ctfassets.net/l6vo76cztcax/54byI2c62blpOTL2LBAGyO/10328bd063218d3a0762e470644cdb90/logo-tonies.svg" alt="Tonies Logo" className="logo" />
                <h1>tonies Refund / Claim</h1>
                {isAuthenticated && (
                    <button className="logout-button" onClick={onLogout}>Logout</button>
                )}
            </div>
        </header>
    );
};

export default Header;

import React, { useState, useEffect } from 'react';
import './CRUD.css';

const ensureHttpsProtocol = (url) => {
  if (!url) return '';
  return url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
};

const getOrderUrl = (orderId, orderNumber) => {
  if (!orderId || !orderNumber) return '';
  return orderNumber.includes('GBC')
    ? `https://mc.europe-west1.gcp.commercetools.com/tonies-b2c-uk/orders/${orderId}/general`
    : `https://mc.europe-west1.gcp.commercetools.com/tonies/orders/${orderId}/general`;
};

// Function to format the timestamp in German format with timezone respected
const formatDateInGermanLocale = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'Europe/Berlin' // Convert to Berlin timezone
  });
};

const ItemList = ({ items, filters, getStatusLabel }) => {
  const [sortedItems, setSortedItems] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    setSortedItems(applyFilters(items));
  }, [items, filters]);

  const sortByDate = () => {
    const sorted = [...sortedItems].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
    setSortedItems(sorted);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const sortByDateReceived = () => {
    const sorted = [...sortedItems].sort((a, b) => {
      const dateA = a.receivedAt ? new Date(a.receivedAt) : null;
      const dateB = b.receivedAt ? new Date(b.receivedAt) : null;
      if (!dateA || !dateB) return !dateA ? 1 : -1; // Handle null or undefined dates
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
    setSortedItems(sorted);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const applyFilters = (items) => {
    return items.filter(item => {
      const createdAt = new Date(item.createdAt);
      const createdAtFrom = filters.createdAtFrom ? new Date(filters.createdAtFrom) : null;
      const createdAtTo = filters.createdAtTo ? new Date(filters.createdAtTo) : null;

      return (
        (filters.rmaNumber === '' || (item.id && item.id.includes(filters.rmaNumber))) &&
        (filters.type === '' || item.type === filters.type) &&
        (filters.status === '' || item.status === filters.status) &&
        (filters.name === '' || (item.name && item.name.includes(filters.name))) &&
        (filters.orderNumber === '' || (item.orderNumber && item.orderNumber.includes(filters.orderNumber))) &&
        (filters.salesReturnOrderNumber === '' || (item.salesReturnOrderNumber && item.salesReturnOrderNumber.includes(filters.salesReturnOrderNumber))) &&
        (filters.ticketId === '' || (item.ticketId && item.ticketId.includes(filters.ticketId))) &&
        (filters.arvatoId === '' || (item.arvatoId && item.arvatoId.includes(filters.arvatoId))) &&
        (filters.customerNumber === '' || (item.customerNumber && item.customerNumber.includes(filters.customerNumber))) &&
        (!createdAtFrom || createdAt >= createdAtFrom) &&
        (!createdAtTo || createdAt <= createdAtTo)
      );
    });
  };

  const filteredItems = applyFilters(sortedItems);

  return (
    <div>
      <h2>Refunds & Claims ({items.length})</h2>
      <table className="item-table">
        <thead>
          <tr>
            <th>RMA Number</th>
            <th>Type</th>
            <th onClick={sortByDate} style={{ cursor: 'pointer' }}>
              Created at {sortOrder === 'asc' ? '↑' : '↓'}
            </th>
            <th>Name</th>
            <th>Status</th>
            <th>Order Number</th>
            <th>Sales Return Order Number</th>
            <th>Sales Replacement Order Number</th>
            <th>Zendesk Ticket Id</th>
            <th>Arvato Id</th>
            <th onClick={sortByDateReceived} style={{ cursor: 'pointer' }}>
              Received at Arvato {sortOrder === 'asc' ? '↑' : '↓'}
            </th>
            <th>Customer Number</th>
            <th>Last Updated</th>
          </tr>
        </thead>
        <tbody>
          {filteredItems.map((item, index) => (
            <tr key={`${item.id}-${index}`} className={index % 2 === 0 ? 'item-row even' : 'item-row odd'}>
              <td>{item.id}</td>
              <td>{item.type}</td>
              <td>{formatDateInGermanLocale(item.createdAt)}</td>
              <td>{item.name}</td>
              <td>{getStatusLabel(item.status)}</td>
              <td>
                <a href={getOrderUrl(item.orderId, item.orderNumber)} target="_blank" rel="noreferrer">
                  {item.orderNumber}
                </a>
              </td>
              <td>{item.salesReturnOrderNumber}</td>
              <td>{item.salesReplacementOrderNumber}</td>
              <td>
                <a href={`https://tonies.zendesk.com/agent/tickets/${item.ticketId}`} target="_blank" rel="noreferrer">
                  {item.ticketId}
                </a>
              </td>
              <td>{item.arvatoId}</td>
              <td>{formatDateInGermanLocale(item.receivedAt)}</td> {/* Fix sorting by ensuring date parsing */}
              <td>{item.customerNumber}</td>
              <td>{formatDateInGermanLocale(item.updatedAt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ItemList;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { apiConfig } from '../../config';
import ItemList from './ItemList';
import SearchItems from './SearchItems';
import Popup from './Popup';
import { CSVLink } from 'react-csv';
import './CRUD.css';
import { useInView } from 'react-intersection-observer';

const CRUD = ({ idToken }) => {
  const [items, setItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [lastEvaluatedKey1, setLastEvaluatedKey1] = useState(null);
  const [lastEvaluatedKey2, setLastEvaluatedKey2] = useState(null);
  const [originalItems, setOriginalItems] = useState([]);
  const [paginationKeys, setPaginationKeys] = useState([]);
  const [searchType, setSearchType] = useState('RMA');
  const [searchQuery, setSearchQuery] = useState('');
  const [userType, setUserType] = useState('B2C'); // Add userType state with B2C as default
  const [showPopup, setShowPopup] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [filters, setFilters] = useState({
    type: '',
    status: '',
    rmaNumber: '',
    name: '',
    orderNumber: '',
    salesReturnOrderNumber: '',
    ticketId: '',
    arvatoId: '',
    customerNumber: '',
    createdAtFrom: '',
    createdAtTo: '',
    rmaUKFilter: 'All', 
    channelFilter: 'All', 
  });
  const { ref, inView } = useInView({ triggerOnce: false });

  useEffect(() => {
    fetchInitialItems();

    const interceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          setShowPopup(true);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  useEffect(() => {
    if (inView && !isSearching) {
      handleNext();
    }
  }, [inView]);

  useEffect(() => {
    setItems(applyFilters(originalItems));
  }, [filters]);

  const handleUserTypeChange = (e) => {
    setUserType(e.target.value);
  };

  const fetchFromAPI = async (url, headers, params) => {
    const response = await axios.get(url, { headers, params });
    return {
      items: response.data.items,
      total: response.data.total,
      lastEvaluatedKey: response.data.lastEvaluatedKey || null,
    };
  };

  const fetchFromBothAPIs = async (url1, url2, headers, params) => {
    const [response1, response2] = await Promise.all([
      axios.get(url1, { headers, params }),
      axios.get(url2, { headers, params })
    ]);
    return {
      items: [...response1.data.items, ...response2.data.items],
      total: response1.data.total + response2.data.total,
      lastEvaluatedKey1: response1.data.lastEvaluatedKey || null,
      lastEvaluatedKey2: response2.data.lastEvaluatedKey || null,
    };
  };

  const fetchInitialItems = async () => {
    try {
      const response = await fetchFromBothAPIs(
        `${apiConfig.apiUrl}/${apiConfig.env}/items`,
        `${apiConfig.apiUrlB2B}/${apiConfig.env}/items`,
        { Authorization: idToken },
        { limit: 50 }
      );

      setOriginalItems(response.items);
      setItems(response.items);
      setTotalItems(response.total);
      setPaginationKeys([null]);
      setLastEvaluatedKey1(response.lastEvaluatedKey1);
      setLastEvaluatedKey2(response.lastEvaluatedKey2);
      console.log('Initial items fetched:', response.items);
    } catch (error) {
      console.log('Error fetching initial items', error);
    }
  };

  const fetchItems = async (startKey = null, startKeyB2B = null) => {
    try {
      const response = await fetchFromBothAPIs(
        `${apiConfig.apiUrl}/${apiConfig.env}/items`,
        `${apiConfig.apiUrlB2B}/${apiConfig.env}/items`,
        { Authorization: idToken },
        { limit: 50, startKey: startKey || "", startKeyB2B: startKeyB2B || "" }
      );

      const newItems = response.items;
      const updatedOriginalItems = [...originalItems, ...newItems];
      setOriginalItems(updatedOriginalItems);
      setItems(applyFilters(updatedOriginalItems));
      setTotalItems(response.total);

      if (startKey || startKeyB2B) {
        setPaginationKeys([...paginationKeys, { key1: startKey, key2: startKeyB2B }]);
      } else {
        setPaginationKeys([null]);
      }

      setLastEvaluatedKey1(response.lastEvaluatedKey1);
      setLastEvaluatedKey2(response.lastEvaluatedKey2);
      console.log('Items fetched:', response.items);
    } catch (error) {
      console.log('Error fetching items', error);
    }
  };

  const searchItems = async (startKey = null, startKeyB2B = null) => {
    setIsSearching(true);
    try {
      let accumulatedItems = [];
      let currentLastEvaluatedKey1 = startKey;
      let currentLastEvaluatedKey2 = startKeyB2B;

      const isRMAQuery = searchQuery.toUpperCase().startsWith('RMA');
      const isB2C = isRMAQuery && searchQuery.toUpperCase().match(/RMA\w{2}C/); 
      const isB2B = isRMAQuery && searchQuery.toUpperCase().match(/RMA\w{2}B/); 

      if (isB2C) {
        while (accumulatedItems.length < 50) {
          const response = await fetchFromAPI(
            `${apiConfig.apiUrl}/${apiConfig.env}/search`,
            { Authorization: idToken },
            { [searchType]: searchQuery || "", startKey: currentLastEvaluatedKey1 || "" }
          );
          accumulatedItems = [...accumulatedItems, ...response.items];
          currentLastEvaluatedKey1 = response.lastEvaluatedKey;
          if (!currentLastEvaluatedKey1) break;
        }
      } else if (isB2B) {
        while (accumulatedItems.length < 50) {
          const response = await fetchFromAPI(
            `${apiConfig.apiUrlB2B}/${apiConfig.env}/search`,
            { Authorization: idToken },
            { [searchType]: searchQuery || "", startKeyB2B: currentLastEvaluatedKey2 || "" }
          );
          accumulatedItems = [...accumulatedItems, ...response.items];
          currentLastEvaluatedKey2 = response.lastEvaluatedKey;
          if (!currentLastEvaluatedKey2) break;
        }
      } else {
        // Logic for generic search with userType selection
        if (userType === 'B2C') {
          // Only search in B2C API if user selected B2C
          while (accumulatedItems.length < 50) {
            const response = await fetchFromAPI(
              `${apiConfig.apiUrl}/${apiConfig.env}/search`,
              { Authorization: idToken },
              { [searchType]: searchQuery || "", startKey: currentLastEvaluatedKey1 || "" }
            );
            accumulatedItems = [...accumulatedItems, ...response.items];
            currentLastEvaluatedKey1 = response.lastEvaluatedKey;
            if (!currentLastEvaluatedKey1) break;
          }
        } else if (userType === 'B2B') {
          // Only search in B2B API if user selected B2B
          while (accumulatedItems.length < 50) {
            const response = await fetchFromAPI(
              `${apiConfig.apiUrlB2B}/${apiConfig.env}/search`,
              { Authorization: idToken },
              { [searchType]: searchQuery || "", startKeyB2B: currentLastEvaluatedKey2 || "" }
            );
            accumulatedItems = [...accumulatedItems, ...response.items];
            currentLastEvaluatedKey2 = response.lastEvaluatedKey;
            if (!currentLastEvaluatedKey2) break;
          }
        }
      }

      setItems(accumulatedItems);
      setTotalItems(accumulatedItems.length);
      setPaginationKeys([null]);
      setLastEvaluatedKey1(currentLastEvaluatedKey1);
      setLastEvaluatedKey2(currentLastEvaluatedKey2);
      console.log('Search items fetched:', accumulatedItems);
    } catch (error) {
      console.log('Error searching items', error);
    } finally {
      setIsSearching(false);
    }
  };

  const handleNext = () => {
    if (!isSearching && (lastEvaluatedKey1 || lastEvaluatedKey2)) {
      searchQuery ? searchItems(lastEvaluatedKey1, lastEvaluatedKey2) : fetchItems(lastEvaluatedKey1, lastEvaluatedKey2);
    }
  };

  const handleSearch = () => {
    setItems([]);
    setPaginationKeys([]);
    setLastEvaluatedKey1(null);
    setLastEvaluatedKey2(null);
    searchItems();
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const resetFilters = () => {
    setFilters({
      type: '',
      status: '',
      rmaNumber: '',
      name: '',
      orderNumber: '',
      salesReturnOrderNumber: '',
      ticketId: '',
      arvatoId: '',
      customerNumber: '',
      createdAtFrom: '',
      createdAtTo: '',
      rmaUKFilter: 'All',
      channelFilter: 'All',
    });
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleLogout = () => {
    console.log('User logged out');
  };

  const handleBackToList = () => {
    setSearchQuery('');
    resetFilters();
    setItems(originalItems);
    setPaginationKeys([null]);
    setLastEvaluatedKey1(null);
    setLastEvaluatedKey2(null);
    console.log('Reset to original items:', originalItems);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const uniqueTypes = [...new Set(originalItems.map(item => item.type))];
  const uniqueStatuses = [...new Set(originalItems.map(item => item.status))];

  const applyFilters = (items) => {
    return items.filter(item => {
      const createdAt = new Date(item.createdAt);
      const createdAtFrom = filters.createdAtFrom ? new Date(filters.createdAtFrom) : null;
      const createdAtTo = filters.createdAtTo ? new Date(filters.createdAtTo) : null;

      let passesRmaUKFilter = true;
      if (filters.rmaUKFilter === 'UK Only') {
        passesRmaUKFilter = item.id.includes('GBC') || (item.orderNumber && item.orderNumber.includes('GBC'));
      } else if (filters.rmaUKFilter === 'Exclude UK') {
        passesRmaUKFilter = !item.id.includes('GBC') && (!item.orderNumber || !item.orderNumber.includes('GBC'));
      }

      let passesChannelFilter = true;
      if (filters.channelFilter === 'B2C') {
        passesChannelFilter = item.id.match(/RMA..C/);
      } else if (filters.channelFilter === 'B2B') {
        passesChannelFilter = item.id.match(/RMA..B/);
      }

      return (
        passesRmaUKFilter &&
        passesChannelFilter &&
        (filters.rmaNumber === '' || (item.id && item.id.includes(filters.rmaNumber))) &&
        (filters.type === '' || item.type === filters.type) &&
        (filters.status === '' || item.status === filters.status) &&
        (filters.name === '' || (item.name && item.name.includes(filters.name))) &&
        (filters.orderNumber === '' || (item.orderNumber && item.orderNumber.includes(filters.orderNumber))) &&
        (filters.salesReturnOrderNumber === '' || (item.salesReturnOrderNumber && item.salesReturnOrderNumber.includes(filters.salesReturnOrderNumber))) &&
        (filters.ticketId === '' || (item.ticketId && item.ticketId.includes(filters.ticketId))) &&
        (filters.arvatoId === '' || (item.arvatoId && item.arvatoId.includes(filters.arvatoId))) &&
        (filters.customerNumber === '' || (item.customerNumber && item.customerNumber.includes(filters.customerNumber))) &&
        (!createdAtFrom || createdAt >= createdAtFrom) &&
        (!createdAtTo || createdAt <= createdAtTo)
      );
    });
  };


  const statusMapping = {
    draft: "Zendesk Ticket created for unadvised Return. Check Sales Return Orders.",
    retrieveAddress: "Waiting for Customer to enter address",
    refundRegistered: "Return Label Sent, waiting for DHL Shipment",
    returnLabelSent: "Return Label Sent, waiting for DHL Shipment",
    addressRetrieved: "Return Address entered, Return Label not sent yet or failed",
    refundAdvised: "Advised to Arvato",
    itemsRefunded: "Refunded in Adyen, not booked in ERP yet",
    defectsReceived: "Defect Items received and invoiced.",
    advisedRefundBooked: "Refund received and invoiced.",
    refunded: "Refund received and invoiced."
  };

  const getStatusLabel = (status) => {
    return statusMapping[status] || status;
  };

  return (
    <div>
      <Popup show={showPopup} onClose={handleClosePopup} message="Your session has expired. Please reload the page." title="Session Expired" />

      <div className="search-and-download">
        <SearchItems
          searchType={searchType}
          setSearchType={setSearchType}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleSearch={handleSearch}
          handleBackToList={handleBackToList}
          isSearching={isSearching}
        />
      {!searchQuery.toUpperCase().startsWith('RMA') && (
        <div className="channel-type-filter">
          <label>Select Channel:</label>
          <select name="userType" value={userType} onChange={handleUserTypeChange}>
            <option value="B2C">B2C</option>
            <option value="B2B">B2B</option>
          </select>
        </div>
      )}
        <CSVLink 
          data={items} 
          filename="visible_items.csv" 
          className="btn-download"
          style={{ float: 'right', marginRight: '20px', marginTop: '10px' }}
        >
          Download CSV
        </CSVLink>
      </div>

      <div className="filters">
        <h3>Filter</h3>
        <div className="filter-row">
          <div className="filter-item">
            <label>RMA UK Filter:</label>
            <select name="rmaUKFilter" value={filters.rmaUKFilter} onChange={handleFilterChange}>
              <option value="All">All</option>
              <option value="UK Only">UK Only</option>
              <option value="Exclude UK">Exclude UK</option>
            </select>
          </div>
          <div className="filter-item">
            <label>Channels:</label>
            <select name="channelFilter" value={filters.channelFilter} onChange={handleFilterChange}>
              <option value="All">All</option>
              <option value="B2C">B2C</option>
              <option value="B2B">B2B</option>
            </select>
          </div>
          <div className="filter-item">
            <label>RMA Number:</label>
            <input
              type="text"
              name="rmaNumber"
              value={filters.rmaNumber}
              onChange={handleFilterChange}
            />
          </div>
          <div className="filter-item">
            <label>Type:</label>
            <select name="type" value={filters.type} onChange={handleFilterChange}>
              <option value="">All</option>
              {uniqueTypes.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>
          <div className="filter-item">
            <label>Status:</label>
            <select name="status" value={filters.status} onChange={handleFilterChange}>
              <option value="">All</option>
              {uniqueStatuses.map(status => (
                <option key={status} value={status}>{getStatusLabel(status)}</option>
              ))}
            </select>
          </div>
          <div className="filter-item">
            <label>Name:</label>
            <input
              type="text"
              name="name"
              value={filters.name}
              onChange={handleFilterChange}
            />
          </div>
          <div className="filter-item">
            <label>Order Number:</label>
            <input
              type="text"
              name="orderNumber"
              value={filters.orderNumber}
              onChange={handleFilterChange}
            />
          </div>
          <div className="filter-item">
            <label>Sales Return Order Number:</label>
            <input
              type="text"
              name="salesReturnOrderNumber"
              value={filters.salesReturnOrderNumber}
              onChange={handleFilterChange}
            />
          </div>
          <div className="filter-item">
            <label>Zendesk Ticket Id:</label>
            <input
              type="text"
              name="ticketId"
              value={filters.ticketId}
              onChange={handleFilterChange}
            />
          </div>
          <div className="filter-item">
            <label>Arvato Id:</label>
            <input
              type="text"
              name="arvatoId"
              value={filters.arvatoId}
              onChange={handleFilterChange}
            />
          </div>
          <div className="filter-item">
            <label>Customer Number:</label>
            <input
              type="text"
              name="customerNumber"
              value={filters.customerNumber}
              onChange={handleFilterChange}
            />
          </div>
          <div className="filter-item">
            <label>Created At From:</label>
            <input
              type="date"
              name="createdAtFrom"
              value={filters.createdAtFrom}
              onChange={handleFilterChange}
            />
          </div>
          <div className="filter-item">
            <label>Created At To:</label>
            <input
              type="date"
              name="createdAtTo"
              value={filters.createdAtTo}
              onChange={handleFilterChange}
            />
          </div>
          <div className="filter-item">
            <button onClick={resetFilters}>Reset Filters</button>
          </div>
        </div>
      </div>

      <ItemList items={items} filters={filters} getStatusLabel={getStatusLabel} />

      <div ref={ref}></div>

      <button className="back-to-top" onClick={scrollToTop}>Back to Top</button>
    </div>
  );
};

export default CRUD;

import React, { useState } from 'react';
import './Login.css'; // Import the CSS file
import { signIn, signInWithRedirect } from '@aws-amplify/auth';



// const userPool = new CognitoUserPool(poolData);

const SignIn = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSignIn = async () => {
        try {
            await signIn(username, password);
            console.log('sign in successful');
            window.location.reload(); // Reload the page to trigger the authenticated state
        } catch (error) {
            console.log('error signing in', error);
        }
    };

    const handleGoogleSignIn = () => {
        signInWithRedirect({ provider: 'Google' });
        console.log('sign in with google successful');
    };

    return (
        <div>
            <input
                name="username"
                onChange={(e) => setUsername(e.target.value)}
                placeholder="username"
            />
            <input
                name="password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="password"
            />
            <button onClick={handleSignIn}>Sign In</button>
            <button className="google-signin-button" onClick={handleGoogleSignIn}>
                <img src="https://developers.google.com/identity/images/btn_google_signin_dark_normal_web.png" alt="Sign-In with Google" />
            </button>
        </div>
    );
};

export default SignIn;
const poolData = {
    UserPoolId: process.env.REACT_APP_UserPoolId,
    ClientId: process.env.REACT_APP_Cognito_ClientId,
    userPoolDomain: process.env.REACT_APP_userPoolDomain,
    redirectUrl: process.env.REACT_APP_REDIRECT
};


const mapboxConfig = {
    apiKey: process.env.REACT_APP_MAPBOX_TOKEN,
    baseUrl: 'https://api.mapbox.com/geocoding/v5/mapbox.places/'
};
const apiConfig = {
    apiUrl: process.env.REACT_APP_API_URL,
    apiUrlB2B: process.env.REACT_APP_API_URL_B2B,
    env: process.env.REACT_APP_ENV
};

export { poolData, apiConfig, mapboxConfig };

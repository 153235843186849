import React from 'react';
import Header from './CRUD/Header';
import Footer from './CRUD/Footer';
import './MainLayout.css'; // Import the CSS file

const MainLayout = ({ children, onLogout, isAuthenticated }) => {
  return (
    <div className="main-layout">
      <Header onLogout={onLogout} isAuthenticated={isAuthenticated} />
      <main className="content">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default MainLayout;

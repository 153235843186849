import React from 'react';
import './CRUD.css'; // Import the CSS file

const SearchItems = ({ searchType, setSearchType, searchQuery, setSearchQuery, handleSearch, handleBackToList,isSearching }) => {
  return (
    <div className="search">
      <h2>Search Refunds and Claims</h2>
      <div>
        <select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
          <option value="RMA">RMA Number</option>
          <option value="ticketId">Zendesk Ticket ID</option>
          <option value="salesReturnOrderNo">Sales Order Number</option>
          <option value="email">Email</option>
          <option value="name">Customer Name</option>
          <option value="customerNumber">Customer Number</option>
          <option value="orderNumber">Commercetools Order Number</option>
          <option value="arvatoId">Arvato ID</option>
        </select>
        <input
          type="text"
          placeholder={`Search by ${searchType}`}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={handleSearch}>Search</button>
        <button onClick={handleBackToList}>Reset Search</button>
        {isSearching && <div className="spinner"></div>}
      </div>
    </div>
  );
};

export default SearchItems;

import React, { useState, useEffect } from 'react';
import SignIn from './components/SignIn';
import CRUD from './components/CRUD/CRUD';
import MainLayout from './components/MainLayout';
import { Amplify } from 'aws-amplify';
import { poolData } from './config';
import { signInWithRedirect, getCurrentUser, signOut, fetchAuthSession } from '@aws-amplify/auth';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: poolData.ClientId,
      userPoolId: poolData.UserPoolId,
      loginWith: { // Optional
        oauth: {
          domain: poolData.userPoolDomain,
          scopes: ['openid', 'email', 'profile'],
          redirectSignIn: ['http://localhost:3000', poolData.redirectUrl],
          redirectSignOut: ['http://localhost:3000', poolData.redirectUrl],
          responseType: 'code',
        },
        username: 'true',
        email: 'false', // Optional
        phone: 'false', // Optional
      }
    }
  }
});


function App() {
  const [user, setUser] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);


  useEffect(() => {
    const checkUser = async () => {
      try {
        const user = await getCurrentUser();
        setUser(user);
        const session = await fetchAuthSession();
        const idToken = session.tokens?.idToken?.toString();
        setIsAuthenticated(true);
        setIdToken(idToken);
      } catch (error) {
        console.error('Error fetching user session:', error);
        setUser(null);
        setIdToken(null);
        setIsAuthenticated(false);
      }
    };

    checkUser();
  }, []);

  const handleGoogleSignIn = async () => {
    try {
      await signOut(); // Ensure the current user is signed out
      signInWithRedirect({ provider: 'Google' });
    } catch (error) {
      console.error('Error during federated sign-in', error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      setUser(null);
      setIdToken(null);
      setIsAuthenticated(false);
    } catch (error) {
      console.error('Error signing out', error);
    }
  };

  if (!user) {
    return (
      <MainLayout onLogout={handleSignOut} isAuthenticated={isAuthenticated}>
        <div className='login-form'>
          <h2>Login</h2>
          <SignIn />
        </div>
      </MainLayout>
    );
  }

  return (
    <MainLayout onLogout={handleSignOut} isAuthenticated={isAuthenticated}>
      {!isAuthenticated ? (
        <SignIn />
      ) : (
        <CRUD idToken={idToken} />
      )}
    </MainLayout>
  );
}

export default App;